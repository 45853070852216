import React from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useHydrated } from 'remix-utils/use-hydrated';
import { useCopyToClipboard } from 'usehooks-ts';
import { Link, useFetcher } from '@remix-run/react';
import {
  BadgeEuro,
  Ban,
  Bot,
  CircleUserRound,
  Copy,
  CopyCheck,
  DramaIcon,
  ExternalLink,
  Info,
  InfoIcon,
  Shield,
  User,
  UserX2,
} from 'lucide-react';
import type { Gender, UserAvatarFragment } from '~/generated/club.server';
import { getAreaLabel } from '~/utils/area';
import { cn } from '~/utils/cn';
import { formatDate } from '~/utils/date';
import { formatUser } from '~/utils/user';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './AlertDialog';
import { Checkbox } from './Checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from './Dropdown';
import { FormControl, FormDescription, FormItem, FormLabel } from './FormNew';
import { Image } from './Image';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './Tooltip';

export const BAN_EXPLANATION =
  'The user can no longer access our services and cannot see the information or perform any actions.';

const getInitials = (firstName: string, lastName?: string | null) => {
  const _firstName = firstName.replace(/\p{Extended_Pictographic}/gu, '');
  const _lastName = lastName?.replace(/\p{Extended_Pictographic}/gu, '');

  return (_firstName[0] + (_lastName?.[0] || _firstName[1])).toUpperCase();
};

const IMAGE_SIZE = {
  '2xs': 16,
  xs: 24,
  sm: 32,
  md: 40,
  lg: 48,
  xl: 56,
  '2xl': 64,
  '3xl': 80,
};

interface Props extends React.ComponentPropsWithRef<'span'> {
  size: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  className?: string;
  user?: UserAvatarFragment | null;
  disableRing?: boolean;
  disableMenu?: boolean;
}

export const Avatar = ({
  size,
  className,
  user: _user,
  disableRing,
  disableMenu,
  ...props
}: Props) => {
  const isHydrated = useHydrated();
  const [error, setError] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [, startTransition] = React.useTransition();
  const [copiedEmail, copyEmail] = useCopyToClipboard();
  const [copiedPhone, copyPhone] = useCopyToClipboard();
  const fetcher = useFetcher();
  const [banDialogOpen, setBanDialogOpen] = React.useState(false);

  const user = _user || ({} as UserAvatarFragment);
  const { firstName, lastName, gender, avatar, type } = user;
  const url = avatar?.url;
  const isGuest = type === 'GUEST';

  let content = user.communityVettingStatus === 'PENDING' && !isGuest ? 'Community pending' : '';
  if (user.communityVettingStatus === 'REJECTED') content = 'Community rejected';
  if (isGuest && user.activatedAt == null) content = 'Inactive guest';
  if (user.rejectedAt) content = `Rejected ${formatDate(user.rejectedAt, 'MMM D, YYYY, HH:mm')}`;
  if (user.bannedAt) content = `Banned ${formatDate(user.bannedAt, 'MMM D, YYYY, HH:mm')}`;
  if (user.deletedAt) content = `Deleted ${formatDate(user.deletedAt, 'MMM D, YYYY, HH:mm')}`;
  if (user.admin) content = '👑 Admin';

  const isAdmin = !disableRing && user.admin;
  const isDeleted = !disableRing && user.deletedAt != null;
  const isBanned = !disableRing && user.bannedAt != null && !isDeleted;
  const isSubscribed = !disableRing && user.hasValidSubscription;
  const isRejected =
    !disableRing &&
    (user.rejectedAt != null || user.communityVettingStatus === 'REJECTED') &&
    !isDeleted;
  const isCommunityPending =
    !isGuest &&
    !disableRing &&
    user.communityVettingStatus === 'PENDING' &&
    !isRejected &&
    !isBanned &&
    !isDeleted &&
    !isAdmin &&
    !isSubscribed;
  const isGuestInactive =
    !disableRing &&
    isGuest &&
    user.activatedAt == null &&
    !isRejected &&
    !isBanned &&
    !isDeleted &&
    !isAdmin;

  const hasRing =
    !disableRing &&
    (isDeleted ||
      isBanned ||
      isRejected ||
      isCommunityPending ||
      isGuestInactive ||
      isAdmin ||
      isSubscribed);

  const _avatar = (
    <span
      {...props}
      className={cn(
        'group inline-flex aspect-square items-center justify-center overflow-hidden rounded-full bg-background text-muted-foreground',
        {
          'h-4 w-4 min-w-[16px] text-[8px] font-medium': size === '2xs',
          'h-6 w-6 min-w-[24px] text-[10px] font-medium': size === 'xs',
          'h-8 w-8 min-w-[32px] text-sm font-medium': size === 'sm',
          'h-10 w-10 min-w-[40px] text-base font-medium': size === 'md',
          'h-12 w-12 min-w-[48px] text-lg font-medium': size === 'lg',
          'h-14 w-14 min-w-[56px] text-xl font-medium': size === 'xl',
          'h-16 w-16 min-w-[64px] text-2xl font-medium': size === '2xl',
          'h-20 w-20 min-w-[80px] text-3xl font-medium': size === '3xl',
        },
        className,
      )}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick?.(e);
      }}
    >
      <span
        style={
          isAdmin
            ? {
                background:
                  'linear-gradient(white, white) padding-box, linear-gradient(145deg, oklch(67.3% 0.266 25.039656026515278 / 100%) 0.7%, oklch(85.82% 0.201 91.19 / 0.1%) 70%) border-box',
              }
            : undefined
        }
        className={cn(
          'relative flex h-full w-full flex-1 items-center justify-center rounded-full',
          {
            'border-2 border-transparent': hasRing,
            'after:absolute after:-inset-0.5 after:z-11 after:rounded-full after:border-2 after:content-[""]':
              isCommunityPending || isGuestInactive,
            'after:animate-spin after:border-dashed after:border-sky-500 after:duration-10000':
              isCommunityPending,
            'after:animate-spin after:border-dashed after:border-orange-300 after:duration-10000':
              isGuestInactive,
            'border-dashed border-red-500': isRejected,
            'border-solid border-red-500': isBanned,
            'border-solid border-green-500': isSubscribed && !isAdmin,
            ' border-b-green-500 border-r-green-500': isSubscribed && isAdmin,
            'border-gray-400': isDeleted,
            border: hasRing && (size === '2xs' || size === 'xs'),
          },
        )}
      >
        {user.deletedAt ? (
          <span className="absolute inset-0 z-11 flex items-center justify-center rounded-full bg-gray-300 text-gray-400 group-hover:hidden">
            <UserX2 className="size-4" />
          </span>
        ) : null}

        {!error && url && isHydrated ? (
          <Image
            src={url}
            alt={firstName || ''}
            className={cn('aspect-square rounded-full object-cover object-center', {
              'invisible absolute': !isLoaded,
              'h-[calc(100%_-_4px)] w-[calc(100%_-_4px)]': hasRing,
            })}
            onLoad={() => startTransition(() => setIsLoaded(true))}
            onError={() => setError(true)}
            width={IMAGE_SIZE[size]}
            height={IMAGE_SIZE[size]}
          />
        ) : null}
        {((!isLoaded && url) || !url) && firstName ? (
          <span className="z-10 flex size-full items-center justify-center rounded-full bg-muted">
            {getInitials(firstName, lastName)}
          </span>
        ) : null}
        {((!isLoaded && url) || !url) && !firstName && !lastName ? (
          <DefaultAvatar gender={gender} />
        ) : null}
      </span>
    </span>
  );

  if (disableMenu) {
    return _avatar;
  }

  function handlePropagation(e: React.MouseEvent) {
    e.stopPropagation();
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>{_avatar}</DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel className="flex items-center justify-between gap-4">
            <span className="flex flex-col">
              <span className="inline-flex items-center gap-2">
                {formatUser(user)}{' '}
                {user.hasValidSubscription ? <BadgeEuro className="size-4 text-teal-600" /> : null}
              </span>
              {!isGuest && user.area ? (
                <span className="text-xs font-normal text-muted-foreground">
                  {getAreaLabel(user.area, true)}
                </span>
              ) : null}
            </span>
            {content ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <InfoIcon className="inline size-4 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>{content}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : null}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            {user.phoneNumber ? (
              <DropdownMenuItem
                className="group flex items-center justify-between gap-4"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  user.phoneNumber && copyPhone(user.phoneNumber);
                }}
              >
                <span>
                  {parsePhoneNumber(user.phoneNumber)?.format('INTERNATIONAL', {
                    humanReadable: true,
                  })}
                </span>
                {copiedPhone ? (
                  <CopyCheck className="invisible size-3.5 text-muted-foreground group-hover:visible" />
                ) : (
                  <Copy className="invisible size-3.5 text-muted-foreground group-hover:visible" />
                )}
              </DropdownMenuItem>
            ) : null}
            <DropdownMenuItem
              className="group flex items-center justify-between gap-4"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                copyEmail(user.email);
              }}
            >
              <span className="max-w-48 truncate">{user.email}</span>
              {copiedEmail ? (
                <CopyCheck className="invisible size-3.5 text-muted-foreground group-hover:visible" />
              ) : (
                <Copy className="invisible size-3.5 text-muted-foreground group-hover:visible" />
              )}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            {isGuest ? null : (
              <DropdownMenuItem onClick={handlePropagation} asChild>
                <Link to={`/api/colette-thread/${user.id}`} prefetch="intent">
                  <Bot className="mr-2 size-4 text-blue-500" />
                  Colette Bot
                </Link>
              </DropdownMenuItem>
            )}
            {user?.phoneNumber ? (
              <DropdownMenuItem asChild onClick={handlePropagation}>
                <Link
                  to={'https://wa.me/' + user.phoneNumber}
                  target="_blank"
                  className="group flex items-center justify-between"
                >
                  <span className="flex items-center">
                    <svg
                      className="mr-2 size-3.5 text-[#00E676]"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                    </svg>
                    WhatsApp
                  </span>

                  <ExternalLink className="invisible size-3 text-muted-foreground group-hover:visible" />
                </Link>
              </DropdownMenuItem>
            ) : null}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            {isGuest ? null : (
              <DropdownMenuSub>
                <DropdownMenuSubTrigger onClick={handlePropagation}>
                  <Shield className="mr-2 size-4" />
                  Community
                </DropdownMenuSubTrigger>
                <DropdownMenuSubContent>
                  <DropdownMenuRadioGroup
                    onValueChange={(v) => {
                      fetcher.submit(null, {
                        method: 'POST',
                        action: `/users/${user.id}?/${
                          v === 'REJECTED' ? 'rejectFromCommunity' : 'acceptInCommunity'
                        }`,
                      });
                    }}
                    value={user.communityVettingStatus}
                  >
                    <DropdownMenuRadioItem value="ACCEPTED" onClick={handlePropagation}>
                      Accept
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="REJECTED" onClick={handlePropagation}>
                      Reject
                    </DropdownMenuRadioItem>
                  </DropdownMenuRadioGroup>
                </DropdownMenuSubContent>
              </DropdownMenuSub>
            )}
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();

                fetcher.submit(null, {
                  method: 'POST',
                  action: `/users/${user.id}?/${user.rejectedAt ? 'unreject' : 'reject'}`,
                });
              }}
              className="group flex items-center justify-between gap-4"
            >
              <span className="flex items-center">
                <Ban className="mr-2 size-4 text-red-500" />{' '}
                {user.rejectedAt ? 'Unreject' : 'Reject'}
              </span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Info className="ml-2 size-3.5 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>
                    The user always has access to our services but cannot perform any actions. They
                    can see the information.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                if (user.bannedAt) {
                  fetcher.submit(null, {
                    method: 'POST',
                    action: `/users/${user.id}?/unbanUser`,
                  });
                } else {
                  setBanDialogOpen(true);
                }
              }}
              className="group flex items-center justify-between gap-4"
            >
              <span className="flex items-center">
                <Ban className="mr-2 size-4 text-red-500" /> {user.bannedAt ? 'Unban' : 'Ban'}
              </span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Info className="ml-2 aspect-square size-3.5 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>{BAN_EXPLANATION}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            {user?.avatar?.url ? (
              <DropdownMenuItem asChild onClick={handlePropagation}>
                <Link
                  to={user.avatar.url}
                  target="_blank"
                  className="group flex items-center justify-between"
                >
                  <span className="flex items-center">
                    <CircleUserRound className="mr-2 size-4" /> Open avatar
                  </span>
                  <ExternalLink className="invisible size-3 text-muted-foreground group-hover:visible" />
                </Link>
              </DropdownMenuItem>
            ) : null}
            <DropdownMenuItem asChild onClick={handlePropagation}>
              <Link to={`/users/${user.id}/overview`} prefetch="intent">
                <User className="mr-2 size-4" /> Profile
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                fetcher.submit(null, {
                  method: 'POST',
                  action: `/users/${user.id}?/impersonateUserWithMagicLink`,
                });
              }}
            >
              <DramaIcon className="mr-2 size-4" />
              Login as user
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={banDialogOpen} onOpenChange={setBanDialogOpen}>
        <AlertDialogContent onClick={handlePropagation}>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>{BAN_EXPLANATION}</AlertDialogDescription>
          </AlertDialogHeader>

          <fetcher.Form action={`/users/${user.id}?/banUser`} method="POST" id="ban-form">
            <FormItem>
              <div className="flex items-center gap-2">
                <FormControl>
                  <Checkbox name="redactMessages" value="true" />
                </FormControl>
                <FormLabel>Redact Messages</FormLabel>
              </div>
              <FormDescription>
                If checked, all messages from{' '}
                <strong className="font-medium">
                  {user.firstName} {user.lastName}
                </strong>{' '}
                will be redacted.
              </FormDescription>
            </FormItem>
          </fetcher.Form>

          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction form="ban-form" type="submit">
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

const DefaultAvatar = ({ gender }: { gender?: Gender | null }) => {
  return gender === 'FEMALE' ? (
    <svg viewBox="0 0 532 532" className="z-10 text-yellow-400">
      <g>
        <polygon
          points="297.05536 387.22083 309.71625 308.09024 224.2552 273.27277 211.59431 393.55127 297.05536 387.22083"
          fill="#a0616a"
        />
        <path
          d="M382.94672,504.16492c-7.33743,3.57727-14.81442,6.80649-22.40332,9.67133-15.17813,5.74329-30.84525,10.07666-46.91769,12.97015-15.70908,2.83618-31.83749,4.27606-47.96591,4.27606-21.78879,0-43.45168-2.61371-64.40176-7.7851-8.20377-2.01178-16.47781-4.47125-24.61163-7.3374-.85269-.30707-1.71902-.6142-2.57172-.92267-10.09067-3.66052-20.01379-7.9516-29.5592-12.80093-1.28603-.64285-2.57172-1.30069-3.85741-1.99951-1.42558-.7261-2.83717-1.49451-4.26276-2.29156-1.81696-.99225-3.61992-2.02679-5.4229-3.07501-6.03741-3.49402-11.97723-7.26782-17.65159-11.19449l-.37738-.25113c-.33541-.23749-.67082-.46133-.99225-.69879,.2095-1.10416,.40536-2.11142,.58689-3.0054,.6988-3.50766,1.16012-5.43619,1.16012-5.43619l38.26627-79.04932,10.66391-22.02594,5.19905-2.13873,39.27284-16.14206h95.98743l30.28601,12.52249s.1539,.57324,.46133,1.6774c3.01871,10.83145,20.62866,72.32608,47.95193,132.49271,.37704,.83801,.76874,1.69104,1.15979,2.54407l-.00006,.00003Z"
          fill="currentColor"
        />
        <circle cx="275.45874" cy="246.37804" r="87.14982" fill="#a0616a" />
        <path
          d="M167.97998,370.32001l-.14996,.75-26.57001,126.32996-.22998,1.10004-.37006,1.73999c-1.42999-.72998-2.83997-1.5-4.25995-2.28998-1.82001-1-3.62-2.03003-5.43005-3.08002-6.02997-3.48999-11.96997-7.27002-17.64996-11.19l-.38-.26001c-.33002-.22998-.66998-.46002-.98999-.69-4.34003-3.02997-8.57001-6.19-12.70001-9.45001-.03003-.01996-.04999-.03998-.08002-.07001-7.37-5.94-14.46997-12.32996-21.26001-19.12-4.37994-4.38-8.58997-8.88995-12.62-13.51996l.61005-2.56,.06-.26001,.75995-3.26001c6.76001-28.54999,29.63-50.46002,58.44-55.97998l38.28003-7.33002,4.53998-.85999Z"
          fill="currentColor"
        />
        <path
          d="M439.39618,467.22528c-11.27878,9.60172-23.36792,18.29581-36.12823,25.92667-1.24371,.75476-2.51575,1.49451-3.78745,2.23563-5.42255,3.13095-10.94336,6.05173-16.53378,8.77734-7.33743,3.57727-14.81442,6.80649-22.40332,9.67133l-.58722-2.76791-30.00653-142.6813,3.88538,.74112,38.93744,7.46298c28.80444,5.52081,51.68341,27.43481,58.43362,55.98746l7.44934,31.5444,.74078,3.10229h-.00003Z"
          fill="currentColor"
        />
        <path
          d="M375.81912,269.67435c-2.26428,8.44159-13.65463,46.16327-42.82281,54.10123-5.72998,1.56549-19.39859,3.36844-15.77869,0,.82437-.7684,1.62109-1.52316,2.41782-2.26428,31.65561-29.76871,44.56949-47.96487,38.67163-88.35622-3.74582-25.71648-46.40042-49.07042-70.39752-51.39066-10.11865-.97723-23.0325,2.44582-31.55768,11.26546-14.07397,14.53429-11.75407,59.14709-19.55247,85.3522-2.09641,7.01532-4.90561,12.71765-8.94489,16.16937-2.51576,2.15237-4.12286-.58688-5.31097-5.07315-2.5294-9.5321-3.18623-26.98715-6.77818-22.0546-10.38411,14.29816-.4893,33.24908,2.50177,40.5592,.61485,1.49588,1.24371,2.93442,1.87257,4.30472v.01364c.36339,.78207,.74077,1.56549,1.10416,2.30658v.01364c9.97874,20.34854,21.97031,27.63,32.53629,40.30673,4.15086,4.96124,8.0782,10.73315,11.5862,18.44867,2.40384,5.28333,4.13687,10.59396,5.32495,15.8063,6.05139,26.1355-1.13214,49.88113-3.07501,56.33823-4.06689,13.44513-11.05493,36.54657-31.55769,49.5863-25.74413,16.38092-49.23761,1.94354-60.47441,17.49872-.13989,.18152-.26579,.36304-.39137,.54456-.41936,.62921-.79672,1.25836-1.11815,1.88623-10.09067-3.66052-20.01379-7.9516-29.5592-12.80093-1.28603-.64285-2.57172-1.30069-3.85741-1.99951-1.42558-.7261-2.83717-1.49451-4.26276-2.29156-.05595-1.09052-.11192-2.17966-.15388-3.28384-.82437-18.90997,.41936-39.69254,5.12944-60.41913,3.01871-13.23492,6.00977-26.35931,10.59361-39.00739,2.99107-8.24506,6.6526-16.2963,11.47427-24.05273,3.85776-6.23325,8.44159-12.285,14.00403-18.09927,12.21539-12.75998,23.07481-18.08426,24.79384-31.55804,2.45946-19.23071-17.56799-25.0163-24.79384-54.10123-4.93324-19.88721-10.09067-48.55177-5.08713-67.86711,4.72408-18.25212,8.83296-34.0871,22.54355-49.60133,3.29816-3.71785,30.36993-29.57355,61.73212-33.82232,14.45102-1.94218,27.33725,16.56245,44.80731,20.54507,20.0834,4.57088,31.58566,7.18456,42.82248,15.77902,18.99359,14.50697,24.34616,36.19852,29.30774,56.35188,3.88538,15.76537,8.9169,36.1562,2.25027,60.86543l.00003,.00012Z"
          fill="#2f2e41"
        />
      </g>
    </svg>
  ) : (
    <svg viewBox="0 0 532 532" className="z-10 text-yellow-400">
      <g>
        <g>
          <circle cx="270.759" cy="260.92941" r="86.34897" fill="#a0616a" />
          <polygon
            points="199.2879 366.61365 217.2879 320.61365 310.2879 306.61365 320.28003 408.44043 226.28003 410.44043 199.2879 366.61365"
            fill="#a0616a"
          />
        </g>
        <path
          d="M357.94449,276.8613c-1.12067,4.48965-3.38602,15.17972-6.9238,15.23233-2.89023,.04208-5.65668-46.33466-2.76953-54.00568,3.31638-8.81271-5.39886-19.96062-11.96411-25.6832-11.80423-10.2894-38.00696,11.80466-64.65118,1.79587-.70633-.26482-.56558-.23502-8.97934-3.59174-25.88966-10.32974-27.2506-10.62788-28.73386-10.77521-12.55046-1.24167-27.86705,9.02844-34.12146,21.55038-6.50168,13.01653-1.06937,24.18106-7.18346,55.67184-.71246,3.67065-1.83138,8.90216-3.59174,8.97934-3.21819,.14029-6.3605-17.04846-7.18346-21.55038-3.44792-18.86186-6.7722-37.04675,0-57.46771,.73878-2.22729,5.29158-10.49458,14.36693-26.93799,13.0744-23.68825,19.65018-35.57709,21.55038-37.7132,13.62859-15.32624,38.43575-29.30734,59.26357-23.34626,10.52704,3.01299,8.63953,7.85691,21.55038,12.57105,23.00821,8.40057,43.00476-1.87303,46.69254,5.3876,1.9537,3.84602-3.51236,7.01686-3.59174,14.36693-.13593,12.6114,15.81424,16.25575,25.14212,28.73386,5.01447,6.70819,13.59753,6.78012-8.87228,96.78212l.00003,.00003Z"
          fill="#2f2e41"
        />
      </g>
      <path
        d="M464.92017,442.61035c-3.48022,3.91016-7.09009,7.74023-10.83008,11.48047-50.23999,50.23926-117.04004,77.90918-188.09009,77.90918-61.40991,0-119.63989-20.66992-166.75-58.71973-.03003-.01953-.05005-.04004-.07983-.07031-6.25-5.03906-12.30005-10.39941-18.14014-16.05957,.10986-.87988,.22998-1.75,.35986-2.61035,.82007-5.7998,1.73022-11.33008,2.75-16.41992,8.3501-41.71973,118.22021-85.51953,121.08008-86.66016,.04004-.00977,.06006-.01953,.06006-.01953,0,0,14.14014,52.12012,74.72998,51.4502,41.27002-.4502,33.27002-51.4502,33.27002-51.4502,0,0,.5,.09961,1.43994,.2998,11.91992,2.53027,94.68018,20.70996,127.33008,45.52051,9.94995,7.55957,17.08984,23.66016,22.21997,42.85938,.21997,.82031,.42993,1.66016,.65015,2.49023Z"
        fill="currentColor"
      />
    </svg>
  );
};
